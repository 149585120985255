// App.tsx
import React, { useEffect, useState } from "react"
import {
  BrowserRouter as Router,
  Route,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  BrowserRouter,
  useNavigate,
} from "react-router-dom"
import RiderListing from "./layouts/userHome/RiderListing"
import UserListing from "./layouts/ridersHome/UserListing"
import UserPost from "./layouts/usersPost/UserPost"
import Header from "./layouts/Header"
import toast, { Toaster } from "react-hot-toast"
import RiderLogin from "./layouts/login/RiderLogin"
import RiderProfile from "./layouts/profile/RiderProfile"
import ContactUs from "./layouts/contactUs/ContactUs"
import { isRiderApproved, isRiderLoggedIn } from "./constants/helper"
import UserProfile from "./layouts/profile/UserProfile"
import Loading from "./components/PulsatingDot/Loading"

const App = () => {
  // True/False -> Rider
  // null -> Loading
  // undefined -> User
  const [isRiderApprovedState, setIsRiderApprovedState] = useState<boolean | null | undefined>(null)

  useEffect(() => {
    const riderData = isRiderLoggedIn()

    // if no riderData, means User
    if (!riderData) setIsRiderApprovedState(undefined)

    isRiderApproved()
      .then((res) => {
        setIsRiderApprovedState(res)
      })
      .catch((err) => console.error(err))
  }, [])

  useEffect(() => {
    if (isRiderApprovedState === false) {
      toast.error("Please Contact Us For Approval")
    }
  }, [isRiderApprovedState])

  const Layout = () => {
    return (
      <>
        <Header />
        <Toaster />
        <Outlet />
      </>
    )
  }

  const router = createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: "/",
          element:
            isRiderApprovedState === true ? (
              <UserListing />
            ) : isRiderApprovedState === false ? (
              <ContactUs />
            ) : isRiderApprovedState === null ? (
              <Loading />
            ) : (
              <RiderListing />
            ),
        },
        {
          path: "/users",
          element: <UserListing />,
        },
        {
          path: "/create-user-post",
          element: <UserPost />,
        },
        {
          path: "/join-us",
          element: <RiderLogin />,
        },
        {
          path: "/rider-profile",
          element: <RiderProfile />,
        },
        {
          path: "/user-profile",
          element: <UserProfile />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default App
