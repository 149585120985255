import React, { useEffect, useState } from "react"
import { Rider } from "../userHome/userHomeSlice"
import { LOCAL_STORAGE_KEYS, getDataFromLocalStorage } from "../../constants/localStorage"
import { updateRiderProfile } from "../login/loginSlice"
import { IonCheckbox } from "@ionic/react"
import { useNavigate } from "react-router-dom"

export default function RiderProfile() {
  const [formData, setFormData] = useState<Rider>({
    name: "",
    email: "",
    phone: "",
    photoURL: "",
    isAvailable: false,
    notes: "",
    uid: "",
    userType: "Rider",
  })

  const navigate = useNavigate()

  useEffect(() => {
    const localRiderProfileData = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.RiderProfile) as Rider
    // skip the (+1) from the string, it is appended in update API 
    if (localRiderProfileData.phone) localRiderProfileData.phone = localRiderProfileData.phone.substring(1)
    if (!localRiderProfileData) {
      navigate("/")
    } else setFormData(Object.assign({}, formData, localRiderProfileData))
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target
    let checked: boolean | null

    if (e.target instanceof HTMLInputElement) {
      checked = e.target.checked
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      formData.phone = "1" + formData.phone
      // Call the function to update user profile
      await updateRiderProfile(formData.uid, formData)

      // Optionally, redirect or show a success message
      console.log("Profile updated successfully!")
    } catch (error) {
      console.error("Error updating profile:", error)
    }
  }

  return (
    <main>
      <article>
        <section className="section hero">
          <div className="container">
            <div className="hero-content">
              <h2 className="h1 hero-title">Profile</h2>

              {formData.phone?.length === 10 ? null : (
                <p className="hero-text">Please Update Your Phone Number (10 digits only)!</p>
              )}
            </div>
            <div className="hero-banner"></div>
            <form onSubmit={handleSubmit} className="hero-form">
              <div className="input-wrapper">
                <label htmlFor="name">Name:</label>
                <input
                  className="input-field"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="email">Email:</label>
                <input
                  className="input-field"
                  readOnly
                  type="text"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="phone">Phone:</label>
                <input
                  className="input-field"
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  pattern="[0-9]{10}"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-wrapper" style={{ display: "flex" }}>
                <label htmlFor="isAvailable">Available Now:</label>
                <input
                  className="input-field"
                  type="checkbox"
                  id="isAvailable"
                  style={{ width: "50%" }}
                  name="isAvailable"
                  checked={formData.isAvailable}
                  onChange={handleChange}
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="notes">Any Additional Info:</label>
                <input
                  className="input-field"
                  type="text"
                  id="notes"
                  placeholder="Notes"
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                />
              </div>

              <button type="submit" className="btn">
                Update
              </button>
            </form>{" "}
          </div>
        </section>
      </article>
    </main>
  )
}
