import { IonIcon } from "@ionic/react"
import { carOutline, addCircleOutline, personOutline } from "ionicons/icons"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { isRiderLoggedIn } from "../constants/helper"

export default function Header() {
  const [sideBarOpen, setSideBarOpen] = useState(false)

  return (
    <header className="header" data-header>
      <div className="container">
        <div
          className={`overlay ${sideBarOpen ? "active" : ""}`}
          data-overlay
          onClick={() => setSideBarOpen(false)}
        ></div>

        <Link to="/" className="logo">
          <img src="/logo.png" alt="RideCan logo" height={"70px"} />
        </Link>

        <nav className={`navbar ${sideBarOpen ? "active" : ""}`} data-navbar>
          <ul className="navbar-list">
            {isRiderLoggedIn() ? null : (
              <Link
                to="/join-us"
                className="btn"
                style={{ background: "var(--independence)", marginBottom: "1em" }}
                aria-labelledby="aria-label-txt"
              >
                <span id="aria-label-txt" style={{ display: "block" }}>
                  <IonIcon icon={carOutline} style={{ float: "left", padding: "0 5px 0 0" }} />
                  Own a Car? Join Us
                </span>
              </Link>
            )}
            <li>
              <Link to="/" onClick={() => setSideBarOpen(false)} className="navbar-link" data-nav-link>
                Home
              </Link>
            </li>

            <li>
              <Link to="/contact-us" onClick={() => setSideBarOpen(false)} className="navbar-link" data-nav-link>
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>

        <div className="header-actions">
          {/* <div className="header-contact">
            <a href="tel:88002345678" className="contact-link">
              8 800 234 56 78
            </a>

            <span className="contact-time">Mon - Sat: 9:00 am - 6:00 pm</span>
          </div> */}

          {isRiderLoggedIn() ? null : (
            <Link
              to="/create-user-post"
              className="btn"
              aria-labelledby="aria-label-txt"
              style={{ display: "flex", justifyContent: "center", padding: "0 1em" }}
            >
              <IonIcon icon={addCircleOutline} />
              <span id="aria-label-txt" style={{ display: "block" }}>
                Request Ride
              </span>
            </Link>
          )}

          <Link
            to={`${isRiderLoggedIn() ? "/rider-profile" : "/user-profile"}`}
            className="btn user-btn"
            aria-label="Profile"
          >
            <IonIcon icon={personOutline} />
          </Link>

          <button
            className={`nav-toggle-btn ${sideBarOpen ? "active" : ""}`}
            data-nav-toggle-btn
            aria-label="Toggle Menu"
            onClick={() => setSideBarOpen((val) => !val)}
          >
            <span className="one"></span>
            <span className="two"></span>
            <span className="three"></span>
          </button>
        </div>
      </div>
    </header>
  )
}
