import { collection, getDocs, orderBy, query, where } from "firebase/firestore"
import { firestore } from "../../constants/firebase"
import { FIREBASE_COLLECTIONS, USER_TYPES } from "../../constants/helper"
import { equal } from "assert"

export interface Rider {
  name: string
  phone: string
  isAvailable: boolean
  notes: string
  uid: string
  photoURL: string
  email: string
  userType: "Rider"
  isApproved?: boolean
}

export const getRiders = async (): Promise<Rider[]> => {
  const riders: Rider[] = []
  try {
    const q = query(
      collection(firestore, FIREBASE_COLLECTIONS.rider),
      where("isApproved", "==", true),
      where("isAvailable", "==", true)
    )

    const snapShot = await getDocs(q)
    snapShot.forEach((doc) => {
      riders.push({
        name: doc.data().name,
        phone: doc.data().phone,
        isAvailable: doc.data().isAvailable,
        notes: doc.data().notes,
        photoURL: doc.data().photoURL,
        uid: "",
        email: doc.data().email,
        userType: doc.data().userType,
        isApproved: doc.data().isApproved,
      })
    })

    console.info("List of Riders: ", riders)
    return riders
  } catch (error) {
    console.error("Failed Getting Riders", error)
    throw new Error("Failed Getting Riders")
  }
}
