// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getFirestore, initializeFirestore } from "firebase/firestore"
import { GoogleAuthProvider, getAuth } from "firebase/auth"
import { isEnvDev } from "./helper"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDF0YAJlIohE34xA8S-LHdbhpotsstxl50",
  authDomain: isEnvDev() ? "ridecan-e550f.firebaseapp.com" : "ridecan.com",
  databaseURL: "https://ridecan-e550f-default-rtdb.firebaseio.com",
  projectId: "ridecan-e550f",
  storageBucket: "ridecan-e550f.appspot.com",
  messagingSenderId: "852693854753",
  appId: "1:852693854753:web:889be7c2def29300ddd160",
  measurementId: "G-M4J7G5LGVW",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const googleAuthProvier = new GoogleAuthProvider()
googleAuthProvier.addScope("profile")
googleAuthProvier.addScope("email")

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app)

const firestore = getFirestore(app)

export { googleAuthProvier, firestore, auth }
