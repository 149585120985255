import { useEffect } from "react"
import toast from "react-hot-toast"
import { LOCAL_STORAGE_KEYS, getDataFromLocalStorage } from "../../constants/localStorage"
import { handleGoogleLogin } from "./loginSlice"
import { useNavigate } from "react-router-dom"

export default function RiderLogin() {
  const navigate = useNavigate()
  useEffect(() => {
    const userData = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.RiderProfile)
    if (userData) {
      toast("Already Logged In!")
      navigate("/rider-profile")
    } else {
      handleGoogleLogin(navigate)
    }
  }, [])

  return <></>
}
