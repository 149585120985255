import React, { useState } from "react"
import "firebase/firestore"
import { User } from "../ridersHome/riderHomeSlice"
import { postUserRequestForRide } from "./userPostSlice"
import { useNavigate } from "react-router"

const UserPost = () => {
  const initialFormData = {
    name: "",
    phone: "",
    pickUp: "",
    dropOff: "",
    time: new Date(),
    notes: "",
  }
  const [formData, setFormData] = useState<User>(initialFormData)
  const navigate = useNavigate()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      [name]: name === "time" ? new Date(value) : value,
    }))
  }

  const getLocalDate = (value: Date) => {
    const offset = new Date().getTimezoneOffset() * 1000 * 60
    const offsetDate = new Date(value).valueOf() - offset
    const date = new Date(offsetDate).toISOString()
    return date.substring(0, 16)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    // Add 1 for WhatsApp message
    formData.phone = "1" + formData.phone
    try {
      postUserRequestForRide(formData).then((bool) => {
        if (bool) {
          console.log("Successful")
          setFormData(initialFormData)
          navigate("/user-profile")
        }
      })
    } catch (error) {
      console.error("Error submitting ride request:", error)
    }
  }

  return (
    <main>
      <article>
        <section className="section hero" id="home">
          <div className="container">
            <div className="hero-content">
              <h2 className="h1 hero-title">Request a Ride</h2>

              <p className="hero-text">Live in Windsor!</p>
            </div>

            <div className="hero-banner"></div>

            <form onSubmit={handleSubmit} className="hero-form">
              <div className="input-wrapper">
                <label htmlFor="input-1" className="input-label">
                  Name
                </label>

                <input
                  type="text"
                  name="name"
                  id="input-1"
                  className="input-field"
                  placeholder="Enter your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="input-2" className="input-label">
                  Phone Number
                </label>

                <input
                  type="tel"
                  name="phone"
                  id="input-2"
                  className="input-field"
                  placeholder="Enter Your Phone"
                  pattern="[0-9]{10}"
                  maxLength={10}
                  value={`${formData.phone}`}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="input-3" className="input-label">
                  Pickup Location
                </label>

                <input
                  type="text"
                  autoComplete="address-line1"
                  name="pickUp"
                  id="input-3"
                  className="input-field"
                  placeholder="Enter Address"
                  value={formData.pickUp}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="input-4" className="input-label">
                  Dropoff Location
                </label>

                <input
                  type="text"
                  autoComplete="address-line1"
                  name="dropOff"
                  id="input-4"
                  className="input-field"
                  placeholder="Enter Address"
                  value={formData.dropOff}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="input-5" className="input-label">
                  Scheduled Time
                </label>

                <input
                  type="datetime-local"
                  name="time"
                  id="input-5"
                  min={getLocalDate(new Date())}
                  className="input-field"
                  placeholder="Enter the scheduled time for pickup"
                  value={getLocalDate(formData.time)}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="input-6" className="input-label">
                  Any Addtional Info
                </label>

                <input
                  type="text"
                  name="notes"
                  id="input-6"
                  className="input-field"
                  placeholder="Addtional Info"
                  value={formData.notes}
                  onChange={handleChange}
                />
              </div>

              <button type="submit" className="btn">
                Request
              </button>
            </form>
          </div>
        </section>
      </article>
    </main>
  )
}

export default UserPost
