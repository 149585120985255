import toast from "react-hot-toast"
import { LOCAL_STORAGE_KEYS, getDataFromLocalStorage, setDataInLocalStorage } from "./localStorage"
import { Rider } from "../layouts/userHome/userHomeSlice"
import { getRiderProfileFromFirebase, setUpRiderProfile } from "../layouts/login/loginSlice"

export const FIREBASE_COLLECTIONS = {
  rider: "riders",
  users: "users",
}

export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = match[1] ? "+1 " : ""
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}

export const USER_TYPES = {
  Rider: "Rider",
}

export const isRiderLoggedIn = (): Rider | null => {
  const riderProfile = getDataFromLocalStorage(LOCAL_STORAGE_KEYS.RiderProfile)
  return riderProfile || null
}

export const isRiderApproved = async () => {
  const riderData = isRiderLoggedIn()
  if (riderData) {
    const onlineRiderData = await getRiderProfileFromFirebase(riderData.uid)
    setDataInLocalStorage(LOCAL_STORAGE_KEYS.RiderProfile, riderData)
    console.log(onlineRiderData)
    if (onlineRiderData?.isApproved === true) {

      return true
    } else return false
  }
}

export const isEnvDev = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
}

export const formatDate = (date: Date) => {
  return date.toLocaleString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })
}
