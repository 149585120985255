import { Timestamp, collection, getDocs } from "@firebase/firestore"
import { firestore } from "../../constants/firebase"
import { FIREBASE_COLLECTIONS } from "../../constants/helper"
import { orderBy, query } from "firebase/firestore"

export interface User {
  name: string
  phone: string
  pickUp: string
  dropOff: string
  time: Date
  notes: string
  requestID?: string
}

export const getUsers = async (): Promise<User[]> => {
  const users: User[] = []
  try {
    const q = query(collection(firestore, FIREBASE_COLLECTIONS.users), orderBy("time"))

    const snapShot = await getDocs(q)
    snapShot.forEach((doc) => {
      const tempUser = {
        name: doc.data().name,
        phone: doc.data().phone,
        pickUp: doc.data().pickUp,
        dropOff: doc.data().dropOff,
        time: doc.data().time,
        notes: doc.data().notes,
      }
      const tempDate = new Timestamp(tempUser.time.seconds, tempUser.time.nanoseconds).toDate()
      tempUser.time = tempDate
      // ignore old requests.
      if (tempUser.time.getTime() > new Date().getTime()) users.push(tempUser)
    })

    console.info("List of Users: ", users)
  } catch (error) {
    console.error("Failed Getting Users", error)
    throw new Error("Failed Getting Riders")
  }

  return users
}
