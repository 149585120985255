import { useEffect, useState } from "react"
import { Rider, getRiders } from "./userHomeSlice"
import { IonIcon } from "@ionic/react"
import { formatPhoneNumber } from "../../constants/helper"
import PulsatingDot from "../../components/PulsatingDot/PulsatingDot"
import { listCircleOutline, logoWhatsapp } from "ionicons/icons"

export default function RiderListing() {
  const [riders, setRiders] = useState<Rider[]>([])
  useEffect(() => {
    getRiders()
      .then((riders) => {
        if (riders) setRiders(riders)
      })
      .catch((err) => {})
  }, [])

  return (
    <section className="section featured-car">
      <div className="container">
        <div className="title-wrapper">
          <h3 className="h3 section-title" style={{ margin: "auto" }}>
            <b>Available Now in Windsor</b>
          </h3>
        </div>

        <ul className="featured-car-list">
          {riders.length ? (
            riders.map((rider, index) => (
              <li key={"Rider" + index}>
                <div className="featured-car-card">
                  <div className="card-content">
                    <div className="card-title-wrapper">
                      <h3 className="h3 card-title">
                        <h3 style={{ display: "flex" }}>
                          {rider.name}
                          <PulsatingDot />
                        </h3>
                        <span style={{ fontSize: 16 }}>{formatPhoneNumber(rider.phone)}</span>
                      </h3>
                    </div>

                    <ul className="card-list">
                      {rider.notes && (
                        <li className="card-list-item">
                          <IonIcon icon={listCircleOutline} className="additional-notes"></IonIcon>
                          <span className="card-item-text">Notes: {rider.notes} </span>
                        </li>
                      )}
                    </ul>

                    <div className="card-price-wrapper">
                      {/* <button className="btn fav-btn" aria-label="Add to favourite list">
                      <IonIcon icon="heart-outline"></IonIcon>
                    </button> */}

                      <div style={{ display: "flex", justifyContent: "space-evenly", gap: "1em", width: "100%" }}>
                        <button className="btn green" style={{ minWidth: "8em" }}>
                          <a href={`https://wa.me/${rider.phone}/?text=`}>WhatsApp</a>
                        </button>
                        <button className="btn" style={{ minWidth: "8em" }}>
                          <a href={`tel:+${rider.phone}`}>Call</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p style={{ margin: "auto", paddingTop: "10em" }}>No Data Found!</p>
          )}
        </ul>
      </div>
    </section>
  )
}
