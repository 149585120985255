import { User } from "../layouts/ridersHome/riderHomeSlice"

// localStorageUtils.ts
export const LOCAL_STORAGE_KEYS = {
  UserRides: "created_rides",
  RiderProfile: "rider_profile",
}

export const getCreatedRidesFromLocalStorage = (): User[] => {
  const ridesString = localStorage.getItem(LOCAL_STORAGE_KEYS.UserRides)
  return ridesString ? JSON.parse(ridesString) : []
}

export const setCreatedRidesInLocalStorage = (rides: User[]): void => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.UserRides, JSON.stringify(rides))
}

export const setDataInLocalStorage = (key: string, value: Object) => {
  localStorage.setItem(key, JSON.stringify(value || {}))
}

export const getDataFromLocalStorage = (key: string) => {
  try {
    const dataString = localStorage.getItem(key)
    return dataString ? JSON.parse(dataString) : null
  } catch (error) {
    console.error("Error Getting Data From Local Storage")
    return null
  }
}
