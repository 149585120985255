import React, { useEffect, useState } from "react"
import { User, getUsers } from "./riderHomeSlice"
import { IonIcon } from "@ionic/react"
import { formatDate, formatPhoneNumber } from "../../constants/helper"
import { locationOutline, logoWhatsapp } from "ionicons/icons"

function UserListing() {
  const [userList, setUSerList] = useState<User[]>([])

  useEffect(() => {
    getUsers()
      .then((users) => {
        if (users) setUSerList(users)
      })
      .catch((err) => {})
  }, [])

  return (
    <section className="section featured-car" id="featured-car">
      <div className="container">
        <div className="title-wrapper">
          <h3 className="h3 section-title" style={{ margin: "auto" }}>
            <b>Available Rides</b>{" "}
          </h3>
        </div>

        <ul className="featured-car-list">
          {userList.length ? (
            userList.map((user, index) => (
              <li key={"User" + index}>
                <div className="featured-car-card">
                  <div className="card-content">
                    <div className="card-title-wrapper">
                      <h3 className="h3 card-title">
                        <h3>{user.name}</h3>
                        <span style={{ fontSize: 16 }}>{formatPhoneNumber(user.phone)}</span>
                      </h3>

                      <data className="year" value="">
                        {formatDate(user.time)}
                      </data>
                    </div>

                    <ul className="card-list">
                      <li className="card-list-item">
                        <IonIcon icon={locationOutline}></IonIcon>
                        <span className="card-item-text">Pick: {user.pickUp}</span>
                      </li>

                      <li className="card-list-item">
                        <IonIcon icon={locationOutline}></IonIcon>
                        <span className="card-item-text">Drop: {user.dropOff}</span>
                      </li>
                    </ul>

                    <div className="card-price-wrapper">
                      {/* <p className="card-price">
                        <h3>{formatPhoneNumber(user.phone)}</h3>
                      </p> */}

                      {/* <button className="btn fav-btn" aria-label="Add to favourite list">
                      <IonIcon icon="heart-outline"></IonIcon>
                    </button> */}
                      <div style={{ display: "flex", justifyContent: "space-evenly", gap: "1em", width: "100%" }}>
                        <button className="btn green" style={{ minWidth: "8em" }}>
                          <a href={`https://wa.me/${user.phone}/?text=`}>WhatsApp</a>
                        </button>
                        <button className="btn" style={{ minWidth: "8em" }}>
                          <a href={`tel:+${user.phone}`}>Call</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p style={{ margin: "auto", paddingTop: "10em" }}>No Data Found!</p>
          )}
        </ul>
      </div>
    </section>
  )
}

export default UserListing
