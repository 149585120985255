// yourFile.ts
import { addDoc, collection, deleteDoc, doc } from "firebase/firestore"
import { getCreatedRidesFromLocalStorage, setCreatedRidesInLocalStorage } from "../../constants/localStorage"
import { firestore } from "../../constants/firebase"
import { FIREBASE_COLLECTIONS } from "../../constants/helper"
import { User } from "../ridersHome/riderHomeSlice"
import toast from "react-hot-toast"

export const postUserRequestForRide = async (rideInfo: User) => {
  try {
    const response = await addDoc(collection(firestore, FIREBASE_COLLECTIONS.users), rideInfo)

    if (response) {
      const rides = { ...rideInfo, requestID: response.id }
      const existingRides = getCreatedRidesFromLocalStorage()
      const updatedRides = [...existingRides, rides]

      setCreatedRidesInLocalStorage(updatedRides)

      toast.success("Request for Ride Added Successfully! You can check it in you Profile", {duration: 5})

      return true
    }
  } catch (error) {
    // Handle errors
  }
}

export const deleteUserRequestForRide = async (requestID: string) => {
  try {
    // Delete the document in Firestore
    await deleteDoc(doc(firestore, FIREBASE_COLLECTIONS.users, requestID))

    // Update the local storage
    const existingRides = getCreatedRidesFromLocalStorage()
    const updatedRides = existingRides.filter((ride) => ride.requestID !== requestID)

    setCreatedRidesInLocalStorage(updatedRides)
    toast.success("Request Removed Successfully")
    return true
  } catch (error) {
    toast.error("Error Removing Request! Please Contact Admin for Further Assistance")

    console.error("Error deleting user request for a ride:", error)
    // Handle errors accordingly
    return false
  }
}
