import { formatPhoneNumber } from "../../constants/helper"

export default function ContactUs() {
  const riders = [{ name: "Umang Patel", phone: "12263507578" }]
  return (
    <section className="section featured-car">
      <div className="container">
        <div className="title-wrapper">
          <h2 className="h2 section-title">Contact Us for Further Assistance: </h2>
        </div>

        <ul className="featured-car-list">
          {riders.map((rider, index) => (
            <li key={"ConctactUs" + index}>
              <div className="featured-car-card">
                <div className="card-content">
                  <div className="card-title-wrapper">
                    <h3 className="h3 card-title">
                      <a href="#">{rider.name}</a>
                    </h3>
                  </div>
                  <div className="card-price-wrapper">
                    <h3 className="card-price">{formatPhoneNumber(rider.phone)}</h3>

                    <div style={{ display: "flex", justifyContent: "space-evenly", gap: "1em", width: "100%" }}>
                      <button className="btn green" style={{ minWidth: "8em" }}>
                        <a href={`https://wa.me/${rider.phone}/?text=`}>WhatsApp</a>
                      </button>
                      <button className="btn" style={{ minWidth: "8em" }}>
                        <a href={`sms:+${rider.phone}`}>Message</a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
