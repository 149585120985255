import { signInWithPopup } from "firebase/auth"
import { auth, firestore, googleAuthProvier } from "../../constants/firebase"
import { Timestamp, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { FIREBASE_COLLECTIONS, USER_TYPES } from "../../constants/helper"
import toast from "react-hot-toast"
import { LOCAL_STORAGE_KEYS, setDataInLocalStorage } from "../../constants/localStorage"
import { Rider } from "../userHome/userHomeSlice"
import { NavigateFunction } from "react-router-dom"

export const handleGoogleLogin = async (navigate: NavigateFunction) => {
  try {
    const result = await signInWithPopup(auth, googleAuthProvier)

    // Access user information
    const { uid, displayName: name, email, photoURL } = result.user
    const userDocRef = doc(firestore, FIREBASE_COLLECTIONS.rider, uid)
    const userObject = {
      name,
      email,
      photoURL,
      uid,
      userType: USER_TYPES.Rider,
      createdAt: Timestamp.now(),
    }
    // Store user data in Firestore
    await setDoc(userDocRef, userObject, { merge: true })
    toast.success("Logged In Successfully!")
    setDataInLocalStorage(LOCAL_STORAGE_KEYS.RiderProfile, userObject)
    setUpRiderProfile(userObject.uid, navigate)
  } catch (error) {
    console.error("Google login error:", error)
  }
}

export const getRiderProfileFromFirebase = async (uid: string) => {
  const userDocRef = doc(firestore, FIREBASE_COLLECTIONS.rider, uid)
  const userDocSnapshot = await getDoc(userDocRef)
  if (userDocSnapshot.exists()) {
    return userDocSnapshot.data() as Rider
  }
  return null
}

export const setUpRiderProfile = async (uid: string, navigate: NavigateFunction) => {
  const userData = await getRiderProfileFromFirebase(uid)
  if (userData) {
    redirectRiderLoginAccordingly(userData, navigate)
  }
}

export const redirectRiderLoginAccordingly = (rider: Rider, navigate: NavigateFunction) => {
  if (rider.phone) {
    // if isApproved not in rider or it's false, then it's pending for Approval
    if (!("isApproved" in rider && rider.isApproved === true)) {
      navigate("/contact-us")
      toast.error("Please Conctact Us for Approval")
    } else {
      setDataInLocalStorage(LOCAL_STORAGE_KEYS.RiderProfile, rider)

      // User has a phone number, redirect to /
      navigate("/")
    }
  } else {
    // User doesn't have a phone number, redirect to /setup-profile
    navigate("/rider-profile")
  }
}

export const updateRiderProfile = async (uid: string, newProfileData: Partial<Rider>) => {
  const userDocRef = doc(firestore, FIREBASE_COLLECTIONS.rider, uid)

  try {
    const userDocSnapshot = await getDoc(userDocRef)

    if (userDocSnapshot.exists()) {
      // Update Firestore document
      await updateDoc(userDocRef, newProfileData)

      // Update data in local storage
      setDataInLocalStorage(LOCAL_STORAGE_KEYS.RiderProfile, newProfileData)
      toast.success("Profile Updated Successfully")
      window.location.href = "/"
    }
  } catch (error) {
    console.error("Error updating rider profile:", error)
    // Handle errors accordingly
  }
}
